html, body {
  font-family: 'Judson', serif;
  text-align: center;
  height:100%;
}
.landing{
  width: 100vw;
  height: 90vh;
}

#greeting{
  margin-top:10%;
  font-size: 10em;
  text-decoration: underline;
  text-decoration-color: #EB9605;
}
.icon{
  width: auto;
  height: 40px;
}

.icon:hover{
  fill:#EB9605;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
}
#skyline{
  width: 100vw;
  height:auto;
  bottom:0;
  left: 0;
  position: absolute;
}

.nav{
display: flex;
justify-content: space-between;
position:fixed; 
z-index: 1;
top:0;
background-color: white;
width:100vw;
}

ul{
  display: flex;
  list-style-type: none;
}

ul li{
  font-size:20px;
  margin-right: 20px;
}

a{
  text-decoration: none;
  color: black;
  font-weight: bold;
}

a:hover{
  text-decoration: underline;
  text-decoration-color: #EB9605;
  bottom:1px;
  transition: all 0.5s ease-in-out 0s;
}
.aboutSection{
  height: 100vh;
  margin:0;
  } 

.about-container{
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: center;
  margin-bottom: 5%;
}

.aboutImg{ 
  width: 70%;
  border: 4px double #EB9605;
  border-radius: 20%;
}

#txt{
  font-size: 18px;
  margin-top:5%;
  padding-right:15%;
}

#technologies{
  float: left;
  margin:2%;
}
.skills {
  margin-top: 4%;
  display:grid;
  grid-template-rows: 1fr, 1fr;
}

.skill-container p{
  font-weight: bold;
  text-decoration: underline #EB9605;
}
.skillsIcon{
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  justify-content: center;
  align-content: center;
}
.skill{
  margin:auto;
  width: 50%;
}
.projects-container{
  margin:0;
}

.projects{
  display:grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}

.project-card{
  position:relative;
  width:50vw;
  height:40vh;
}

.project-card img{
  height:100%;
  width: 100%;
}

.back-card{
  color:white;
  top:0;
  left:0;
  width:100%;
  height:100%;
  opacity:0;
  position:absolute;
  transition: opacity .5s ease-in-out;
  justify-content: center;
  background-color: rgba(235,150,5,.9);
}
.back-card h2{
  margin-top:8%;
}
.back-card p{
  padding-left: 5%;
  padding-right: 5%;
}
.back-card:hover{
  opacity: 1;
}

.contactSection{
  margin: 0;
  display: grid;
  grid-template-rows:30% 70%;
  height: 95vh;
}

input[type=text] {
  font-family: 'Judson', serif; 
  font-weight: bold;
  width: 60%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

input[type=email] {
  font-family: 'Judson', serif; 
  font-weight: bold;
  width: 60%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

textarea{
  font-family: 'Judson', serif;
  font-weight: bold; 
  width: 60%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
}

.message{
  height:100px;
}

.footer{ 
  position: absolute;
  margin-bottom: 0;
  width: 100%;
  height: 5vh;
}

#si{
  padding-left: 10px;
  height:4vh;
}

.footer h3{
  padding-right: 10px;
  padding-bottom: 4px;
}
.back-card{
  display: grid;
  grid-template-rows: repeat(3, 1fr);
}

.back-card a{
  color: white;
  margin:5%;
}

.back-card a:hover{
  color:black;
  text-decoration: underline;
  text-decoration-color: white;
  bottom:1px;
  transition: all 0.5s ease-in-out 0s;
}

@media screen and (max-width: 850px) {
  body {
    display:inline;
  }
 ul li{
  font-size: 16px;
  }
.aboutSection{
  margin-top: -130px;
}
.about-container{
  display:flex; 
  flex-flow: column;
  overflow: auto;
}
#txt{
  margin:auto;
  padding:2%;
  text-align: left;

}
#greeting{
  margin-top: 50%;
  font-size: 5em;
  font-weight: bold;
}
h1{
  font-size: 18px;
  font-weight: bold;
}
.links{
  display: flex;
  justify-content: center;
}
.skills{
  display: flex;
  flex-direction: column;
}
.skillsIcon{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-content: center;
}
.projects-container{
 margin-top: 70%;
}
.projects{
  display:flex;
  flex-direction: column;
  width:100%;
}
}

@media screen and (max-width: 620px) {
  body {
      display:inline;
    }
  ul li{
    font-size: 16px;
    }
  .aboutSection{
    margin-top: -130px;
  }
  .about-container{
    display:flex; 
    flex-flow: column;
    overflow: auto;
  }
  #txt{
    margin:auto;
    padding:2%;
    text-align: left;

  }
  #greeting{
    margin-top: 50%;
    font-size: 5em;
    font-weight: bold;
  }
  h1{
    font-size: 18px;
    font-weight: bold;
  }
  .links{
    display: flex;
    justify-content: center;
  }
  .skills{
    display: flex;
    flex-direction: column;
    /* margin-bottom: 10%; */
  }
  .skillsIcon{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-content: center;
  }
  .projects-container{
  margin-top: 110%;
  }
  .projects{
    display:flex;
    flex-direction: column;
    width:100%;
  }

  .project-card{
    position:relative;
    width:100vw;
    height:40vh;
  }

  .project-card img{
    height:100%;
    width: 100%;
  }
}